import { useState } from "react";

import { cn } from "~/styles";

export function DebugBreakpoints() {
  const [showGrid, setShowGrid] = useState(false);
  return (
    <div>
      <button
        className="bg-black bg-opacity-80 rounded-md fixed bottom-0 left-0 m-4 p-2 text-white font-mono text-xs max-w-[5ch] text-center w-full hover:ring transition-all hover:ring-black/20 z-50"
        onClick={() => setShowGrid((prev) => !prev)}
      >
        <div className="block sm:hidden md:hidden lg:hidden xl:hidden 2xl:hidden">&empty;</div>
        <div className="hidden sm:block md:hidden lg:hidden xl:hidden 2xl:hidden">sm</div>
        <div className="hidden sm:hidden md:block lg:hidden xl:hidden 2xl:hidden">md</div>
        <div className="hidden sm:hidden md:hidden lg:block xl:hidden 2xl:hidden">lg</div>
        <div className="hidden sm:hidden md:hidden lg:hidden xl:block 2xl:hidden">xl</div>
        <div className="hidden sm:hidden md:hidden lg:hidden xl:hidden 2xl:block">2xl</div>
      </button>
      {/*<div
        className="fixed top-0 bottom-0 left-2 w-[8px] border border-danger z-20"
        draggable={true}
        onDrag={(evt) => {
          console.log("onDrag", evt);
          evt.dataTransfer.setData("text/plain", "");
          evt.dataTransfer.effectAllowed = "move";
        }}
        onDragStart={(evt) => {
          console.log(evt);
          evt.dataTransfer.setData("text/plain", "");
          evt.dataTransfer.effectAllowed = "move";
        }}
        />*/}
      <div className={cn("pointer-events-none transition-opacity", showGrid ? "opacity-100" : "opacity-0")}>
        <div
          className={cn(
            "fixed top-0 bottom-0 w-[1px] border border-danger z-50",
            "before:content-['sm'] before:absolute before:top-0 before:font-mono before:p-2 before:bg-danger before:bg-opacity-40 before:rounded-md before:text-danger before:m-1 before:text-sm before:right-full"
          )}
          style={{ left: `640px` }}
        />
        <div
          className={cn(
            "fixed top-0 bottom-0 w-[1px] border border-danger z-50",
            "before:content-['md'] before:absolute before:top-0 before:font-mono before:p-2 before:bg-danger before:bg-opacity-40 before:rounded-md before:text-danger before:m-1 before:text-sm before:right-full"
          )}
          style={{ left: `768px` }}
        />
        <div
          className={cn(
            "fixed top-0 bottom-0 w-[1px] border border-danger z-50",
            "before:content-['lg'] before:absolute before:top-0 before:font-mono before:p-2 before:bg-danger before:bg-opacity-40 before:rounded-md before:text-danger before:m-1 before:text-sm before:right-full"
          )}
          style={{ left: `1024px` }}
        />
        <div
          className={cn(
            "fixed top-0 bottom-0 w-[1px] border border-danger z-50",
            "before:content-['xl'] before:absolute before:top-0 before:font-mono before:p-2 before:bg-danger before:bg-opacity-40 before:rounded-md before:text-danger before:m-1 before:text-sm before:right-full"
          )}
          style={{ left: `1280px` }}
        />
        <div
          className={cn(
            "fixed top-0 bottom-0 w-[1px] border border-danger z-50",
            "before:content-['2xl'] before:absolute before:top-0 before:font-mono before:p-2 before:bg-danger before:bg-opacity-40 before:rounded-md before:text-danger before:m-1 before:text-sm before:right-full"
          )}
          style={{ left: `1536px` }}
        />
      </div>
    </div>
  );
}
